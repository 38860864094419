<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('View Delivery')}}
        </h3>
        
        
      </div>
      <div class="card-toolbar">
        
        <!-- <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button> -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <!--begin::Form Group-->
        
        <!--begin::Form Group-->

        <div>
  <b-embed
    type="iframe"
    aspect="16by9"
    :src="order.delivery_tracking_url"
    allowfullscreen
  ></b-embed>
</div>
        

        
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>




export default {
  name: "Delivery",

  props: {
    zone: {
        type: Object,
        required: true,
    },
    order: {
        type: Object,
        required: true,
    },
  },
  data() {
    return {
      createData: [],
    };
  },
  
   mounted() {
    
  },
  methods: {
   
  },
};
</script>

